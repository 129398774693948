import { ChevronRightIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { formatAddress } from '@rabbit/elements/shared-components';
import { DTConsumer_Private } from '@rabbit/data/types';

interface AccountAddressListProps {
  body?: DTConsumer_Private;
  personaId?: string;
  onOpenForm: (arg0?: any) => void; //todo type
}

function AccountAddressList({ body, onOpenForm }: AccountAddressListProps) {
  const renderButton = (children: React.ReactNode) => {
    return (
      <div className="font-nunito black flex cursor-pointer gap-4 rounded border bg-gray-50 p-4 hover:bg-gray-100">
        <div>
          <MapPinIcon className="h-5 w-5 flex-shrink-0" />
        </div>
        <div className="flex-grow">{children}</div>
        <div className="flex flex-col justify-center align-middle">
          <ChevronRightIcon className="h-5 w-5 flex-shrink-0" />
        </div>
      </div>
    );
  };

  return (
    <div className="font-nunito flex flex-col gap-6">
      <div>
        <div onClick={() => onOpenForm()}>
          {renderButton('Add new address')}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {Array.isArray(body?.address) &&
          body?.address.map((address, index) => {
            return (
              <div key={index} onClick={() => onOpenForm(address.id)}>
                {renderButton(
                  <div>
                    <div className="font-bold">
                      {address.label
                        ? address.label +
                          (address?.isDefault ? ' (Default)' : '')
                        : 'Address ' + (index + 1).toString()}
                    </div>
                    <div>{formatAddress(address)}</div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default AccountAddressList;
