import { useField, useFormikContext } from 'formik';
import { InputTypeSelectSettingsShape } from '@rabbit/elements/shared-types';
import Select from 'react-select';
import { handleKeyPress } from '../../../utils/helpers';
import { generateSelectStyles } from '../../../utils/consts';

export interface InputTypeSelectProps {
  name: string;
  settings: InputTypeSelectSettingsShape;
}

export function InputTypeSelect({ name, settings, form }: any) {
  const { setFieldValue } = useFormikContext();
  const {
    options,
    placeholder,
    isMulti = false,
    isClearable = true,
    disabled = false,
    onChange: defaultOnChange,
    ...props
  } = settings;
  const [field, meta] = useField(name);

  let errorField = false;
  if (meta?.error && meta?.touched) errorField = true;

  function onChange(option: any) {
    if (isMulti) {
      void setFieldValue(
        name,
        option ? option.map((item: { value: any }) => item.value) : []
      );
    } else {
      void setFieldValue(name, option?.value ? option.value : '');
    }
    defaultOnChange && defaultOnChange(option);
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: { value: any }) => field.value.indexOf(option.value) >= 0
          )
        : options.find(
            (option: { value: any }) => option.value === field.value
          );
    } else {
      return isMulti ? [] : '';
    }
  };

  const getLabel = (value: string) => {
    if (options) {
      return options.find((option: { value: any }) => option.value === value)
        ?.label;
    } else {
      return '';
    }
  };

  return (
    <Select
      {...field}
      {...props}
      ref={settings?.ref}
      options={options}
      isMulti={isMulti}
      classNames={generateSelectStyles(errorField)}
      //styles={errorField ? errorCustomStyles : customStyles}
      placeholder={placeholder}
      defaultValue={
        field?.value?.length
          ? !isMulti || !Array.isArray(field?.value)
            ? field?.value
            : field?.value?.map((str: string) => {
                return { label: getLabel(str), value: str };
              })
          : null
      }
      components={
        settings.dropDown === 'disabled' && {
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }
      }
      value={
        options
          ? isMulti
            ? options.find(
                (option: { value: any }) => option.value === field.value
              )
            : getValue()
          : ''
      }
      error
      onKeyDown={handleKeyPress}
      onChange={onChange}
      isClearable={isClearable}
      isDisabled={disabled}
      menuPosition="fixed"
    />
  );
}

export default InputTypeSelect;
