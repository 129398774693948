import { useNavigate } from 'react-router-dom';
import {
  Heading,
  ButtonIconVertical,
  ClipboardListIcon,
  TrashIcon,
} from '@rabbit/elements/shared-components';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { ShieldCheckIcon, PencilIcon } from '@heroicons/react/24/solid';
import ROUTE_NAME from '../../../utils/url-constants';
import { CaseFlowCaseEditingID, useCaseFlowCase } from '@rabbit/bizproc/react';
import { useAppInfo } from '@rabbit/olive/utils/helpers';
import { t } from 'i18next';
import { ConfigContext } from '@rabbit/config/context';
import { useContext, useState } from 'react';
import { ClaimInitiationWarning } from '@rabbit/olive/components/molecules/ClaimInitiationWarning/ClaimInitiationWarning';

export interface HoldingDetailsActionsSectionProps {
  holdingId: string;
  deleteFn: () => any;
  hintFn: () => any;
  data: ConsumerHoldingSummaryShape;
  personaId: string | null;
  holdingHasBasicInfo: boolean;
}

export function HoldingDetailsActionsSection({
  holdingId,
  deleteFn,
  hintFn,
  data,
  personaId,
  holdingHasBasicInfo,
}: HoldingDetailsActionsSectionProps) {
  const navigate = useNavigate();
  const appInfo = useAppInfo();
  const { config } = useContext(ConfigContext) || {};
  const [showWarningModal, setShowWarningModal] = useState(false);

  // Need to look into a neater way of doing this - dc
  const caseId: CaseFlowCaseEditingID | null = data.active_cases?.length
    ? {
        type: 'cfc' as const,
        case: data.active_cases?.[0] ?? ('' as const),
        operating_persona: personaId ?? '',
        principal_persona: personaId ?? '', // DEV-502, this component just checks if there's a case open for the holding so we can enable/disable holding deletion accordingly. -DC
        isNewCase: false,
      }
    : null;

  const caseIdFallback: CaseFlowCaseEditingID = {
    type: 'cfc',
    // todo: check if an empty string would also work, so the type can avoid using undefined - dc
    case: undefined,
    operating_persona: '',
    principal_persona: '',
    isNewCase: false,
  };
  const { caseFlowCase } = useCaseFlowCase(caseId ? caseId : caseIdFallback);
  const caseState = caseFlowCase?.GetCaseState();

  const isSRV = data.self_registration;
  const disableEdit =
    data.self_registration &&
    (data?.srvInfo?.type === 'vehicle' || data?.srvInfo?.type === 'product');

  const handleInitiateClaim = () => {
    if (config?.CLAIMS.CONSUMER.CAN_INITIATE_CLAIM) {
      navigate(`${ROUTE_NAME.PRODUCTS}/${holdingId}/claim`);
    } else {
      setShowWarningModal(true);
    }
  };

  return (
    <div className="w-full">
      <Heading kind="h3">Product actions</Heading>
      <div className="mt-4 w-full flex flex-wrap gap-5 md:gap-20 rounded-md py-3">
        <ButtonIconVertical
          disabled={
            (isSRV && !holdingHasBasicInfo) || Object.keys(data).length === 0
          }
          hintAction={
            isSRV && !holdingHasBasicInfo ? () => hintFn() : undefined
          }
          kind={'claim-actions'}
          Icon={ClipboardListIcon}
          label={
            data.active_cases && data.active_cases.length > 0
              ? t('View claim')
              : t('Initiate claim')
          }
          onClick={() => {
            if (data.active_cases && data.active_cases.length > 0) {
              navigate(`${ROUTE_NAME.REPAIRS}/${data.active_cases[0]}`);
            } else {
              handleInitiateClaim();
            }
          }}
          className="flex-grow"
        />
        <ButtonIconVertical
          disabled={isSRV && !holdingHasBasicInfo}
          hintAction={
            isSRV && !holdingHasBasicInfo ? () => hintFn() : undefined
          }
          kind="claim-actions"
          Icon={ShieldCheckIcon}
          label={
            appInfo.name === 'NuCover'
              ? 'Care Plan Summary'
              : 'Warranty information'
          }
          onClick={() =>
            navigate(`${ROUTE_NAME.PRODUCTS}/${holdingId}/warranty-info`)
          }
          className="flex-grow"
        />
        {!disableEdit && (
          <ButtonIconVertical
            kind="claim-actions"
            Icon={PencilIcon}
            label="Edit information"
            onClick={() =>
              isSRV
                ? navigate(`${ROUTE_NAME.PRODUCTS}/${holdingId}/edit-srv`)
                : navigate(`${ROUTE_NAME.PRODUCTS}/${holdingId}/edit-info`)
            }
            isActive
            className="flex-grow"
          />
        )}
        <ButtonIconVertical
          kind="claim-actions"
          Icon={TrashIcon}
          label="Delete product"
          onClick={deleteFn}
          disabled={
            true
            // Object.keys(data).length === 0 ||
            // (data.active_cases?.length && caseState !== 'closed')
            //   ? true
            //   : false
          }
          className="flex-grow"
        />
      </div>
      {showWarningModal && (
        <ClaimInitiationWarning onClose={() => setShowWarningModal(false)} />
      )}
    </div>
  );
}

export default HoldingDetailsActionsSection;
