import { useMyConsumerPersona } from '@rabbit/data/portal';

import {
  FBD_Holding_Public,
  FBD_Holding_Manufacturer,
  FBD_Holding_Private,
  Holding,
} from '@rabbit/data/types';

export function useDeleteConsumerHolding(holdingDocid: string) {
  const consumerPersona = useMyConsumerPersona();

  function containsHoldingLink(holdings:Holding[], holdingLink:string) {
    return holdings.some(holding => holding.holdingLink === holdingLink);
  }

  return async () => {
    if (!consumerPersona.personaId || !consumerPersona.data.consumer_private)
      throw new Error('A valid consumer persona is required');

    const [holding, holding_private, holding_manufacturer] = await Promise.all([
      FBD_Holding_Public.get(holdingDocid),
      FBD_Holding_Private.get(holdingDocid),
      FBD_Holding_Manufacturer.get(holdingDocid),
    ]);

    if (!holding || !holding_private || !holding_manufacturer)
      throw new Error(`No holding with id ${holdingDocid} was found`);

    if (!containsHoldingLink(consumerPersona.data.consumer_private.holdings,holdingDocid))
      throw new Error(
        `No holding with id ${holdingDocid} was found among this user's holdings`
      );

    holding.deleted = true;

    // Update all the documents so they have synchronized
    await Promise.all([
      FBD_Holding_Public.set(holding),
      FBD_Holding_Private.set(holding_private),
      FBD_Holding_Manufacturer.set(holding_manufacturer),
    ]);

    return true;
  };
}
