import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/solid';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import {
  calcWarrantyRemaining,
  useConsumerHoldingEditor,
} from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import {
  TableCover,
  UserUploadedDocument,
  VehicleInfo,
} from '@rabbit/data/types';
import {
  formatUnixTime,
  Heading,
  Modal,
  Button,
} from '@rabbit/elements/shared-components';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useAppInfo } from '@rabbit/olive/utils/helpers';
import { getUnixTime } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { OliveFileUploader } from '../../organisms/upload-wrapper/OliveFileUploader';
import imgWarrantyBadge from '../../../assets/images/warranty-badge.png';
import { BL_Warranty } from '@rabbit/bizproc/core';

interface RecordType {
  label: string;
  value: string;
  ValueElement?: JSX.Element;
}

export default function StoneWarrantyDetailSection({
  holding,
}: {
  holding: ConsumerHoldingSummaryShape;
}) {
  const warrantyInfo = BL_Warranty.getLatestWarranty(holding?.warranties);
  const productInfo = holding?.srvInfo?.productInfo as TableCover;
  const appInfo = useAppInfo();
  const { personaId } = useMyConsumerPersona();
  const remainingTime = calcWarrantyRemaining(warrantyInfo);
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [proofOfPurchaseModal, setProofOfPurchaseModal] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const CFG_WARRANTY_INFO_URL = t('CFG_WARRANTY_INFO_URL');
  const { body } = useConsumerHoldingEditor(
    personaId || '',
    holding.holdingId.split('_')[0]
  );

  const renderLabelValue = (info: RecordType) => (
    <div className="font-nunito flex flex-col gap-1">
      <div className="text-sm text-gray-500">{info.label}</div>
      {!info.ValueElement && <div className="text-black">{info.value}</div>}
      {info.ValueElement && info.ValueElement}
    </div>
  );

  const modalSettings = {
    kind: 'generic' as const,
    settings: {
      title: 'Warranty information',
      handleClose: () => setShowWarrantyModal(false),
    },
  };

  const warrantyInfoMap: RecordType[] = [
    {
      label: 'ID',
      value: holding.holdingId.slice(0, 5).toUpperCase(),
    },
    {
      label: 'Service plan type',
      value: warrantyInfo?.templateTitle || '-',
    },
    {
      label: 'Registration date',
      value: holding.purchase_time
        ? formatUnixTime(getUnixTime(holding.purchase_time), 'dd/MM/yyyy')
        : '-',
    },
    {
      label: 'Start date',
      value: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
    },
    {
      label: 'Expiry date',
      value: warrantyInfo?.endDate
        ? formatUnixTime(getUnixTime(warrantyInfo.endDate), 'dd/MM/yyyy')
        : '-',
    },
  ];

  const secondaryInfo: RecordType[] = [
    {
      label: 'Service plan term',
      value:
        warrantyInfo?.duration.amount + ' ' + warrantyInfo?.duration.division,
    },
    {
      label: 'Term remaining',
      value: remainingTime?.amount + ' ' + remainingTime?.unit,
    },
    {
      label: 'Term expired',
      value: new Date() > new Date(warrantyInfo?.endDate || '') ? 'Yes' : 'No',
    },
    {
      label: 'Extended service plan',
      value: 'Yes',
    },
    {
      label: 'Go to',
      value: '',
      ValueElement: RenderGoToUrl(
        CFG_WARRANTY_INFO_URL,
        'Service plan terms & conditions'
      ),
    },
  ];

  return (
    <div className="flex w-full flex-col rounded-md bg-gray-50">
      {!warrantyInfo && <RenderLoadingState />}
      {warrantyInfo && (
        <div className="flex flex-col p-4">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <div className="h-[84px] w-[84px]">
                <img
                  src={imgWarrantyBadge}
                  alt="Warranty"
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="font-nunito flex flex-col gap-1">
                <div className="font-bold">
                  {warrantyInfo.templateTitle || '-'}
                </div>
                <div className="text-gray-500">{t('Service plan')}</div>
                <p className="text-gray-900">
                  {warrantyInfo.retailerName ?? appInfo.name}
                </p>
              </div>
            </div>
            {remainingTime?.amount && remainingTime.amount > 0 && (
              <div className="flex items-start">
                <div className="font-nunito flex flex-col items-center font-medium">
                  <Heading kind={'h2'} color="text-primary-700">
                    {remainingTime.amount.toString()}
                  </Heading>
                  <div className="text-center">
                    <p className="text-primary-700 mt-[-8px] mb-[5px] text-[8px]">
                      {remainingTime.unit}
                    </p>
                    <p className="text-[10px]">
                      {t('Service plan')}
                      <br />
                      {t('Remaining')}
                    </p>
                  </div>
                </div>
                {appInfo?.name
                  .toLowerCase()
                  .includes(String(productInfo?.typeofStone)?.toLowerCase()) &&
                  CFG_WARRANTY_INFO_URL?.length ? (
                  <div
                    className="relative z-20 cursor-pointer"
                    onClick={() => setShowWarrantyModal(true)}
                  >
                    <InformationCircleIcon className="relative top-2 h-5 w-5 text-black" />
                  </div>
                ) : null}
              </div>
            )}
            {remainingTime && remainingTime.amount === -1 && (
              <div className="font-nunito flex flex-col items-center font-medium">
                <p className="text-primary-700 text-2xl font-normal">∞</p>
                <p className="w-[75px] text-center text-[10px]">
                  {t('Lifetime warranty')}
                </p>
              </div>
            )}
            {remainingTime?.amount &&
              (remainingTime.amount < -1 || remainingTime.amount === 0) && (
                <div className="font-nunito flex flex-col items-center font-medium">
                  <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
                  <p className="w-[75px] text-center text-[10px]">
                    {t('Warranty expired')}
                  </p>
                </div>
              )}
          </div>
          <div className="mt-5 grid grid-cols-2 gap-4 p-4 md:grid md:grid-cols-3 lg:flex lg:justify-between">
            {warrantyInfoMap.map((info) => renderLabelValue(info))}
          </div>
          <div className="mt-2 grid grid-cols-2 gap-4 p-4 md:grid-cols-3 lg:flex lg:justify-between">
            {secondaryInfo.map((info) => renderLabelValue(info))}
          </div>
        </div>
      )}

      {showWarrantyModal && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        >
          <div className="font-nunito p-5 text-sm">
            For certain {appInfo?.name} products, component parts may have
            different warranties, please{' '}
            <a href={CFG_WARRANTY_INFO_URL} target="_blank" rel="noreferrer">
              click here
            </a>{' '}
            for more information.
          </div>
        </Modal>
      )}
      {proofOfPurchaseModal && (
        <Modal
          kind="generic"
          settings={{
            title: t('Proof of purchase'),
            handleClose: () => setProofOfPurchaseModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <OliveFileUploader
              label={t('Proof of purchase')}
              identifiers={{
                category: UploadedFileCategories.ConsumerProofPurchase,
                docType: {
                  docid: holding.holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: personaId ?? '',
              }}
              shouldAutoUpdateDocs={true}
              //alterCaseFacts={alterCaseFacts} //No need - it's working without it
              accepts={['image/*', '.pdf']}
              //onUploadCompleted={onProofOfPurchaseUpdated} //No need - it's working without it
              currentFiles={body?.private?.receipt ?? []}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfPurchaseModal(false)}
              >
                OK
              </Button>
              <Button kind="red" onClick={() => setProofOfPurchaseModal(false)}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

const RenderLoadingState = () => (
  <div className="flex flex-col gap-6 p-5">
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
  </div>
);

const RenderPoP = ({
  onClick,
  receipt,
}: {
  onClick: () => void;
  receipt?: UserUploadedDocument[];
}) => {
  const totalFiles = receipt?.length ?? 0;
  return (
    <div
      className={
        'flex cursor-pointer items-center gap-2 ' +
        (totalFiles === 0 ? 'text-red-500' : 'text-black')
      }
      onClick={onClick}
    >
      {totalFiles} file(s) uploaded{' '}
      <div>
        <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
      </div>
    </div>
  );
};

const RenderGoToUrl = (url: string, label: string) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-2 font-medium text-gray-700"
    >
      {label}
      <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
    </a>
  );
};
